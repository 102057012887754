@import "../../../styles/colors.scss";
@import "../../../styles/constants.scss";

@keyframes appear-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.holder {
  height: 60vh;

  .errorMessage {
    color: $white-color;
  }
}

.presentation {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  * {
    color: $white-color;
  }

  .contentControls {
    display: flex;
    justify-content: space-between;

    min-width: 1350px;
    width: 82vw;
    max-width: 1580px;

    .controlHolder {
      cursor: pointer;
      user-select: none;

      width: 434px;

      display: flex;
      justify-content: center;

      .control {

        .h2 {
          text-align: center;
          font-size: 40px;
          font-weight: 400;
        }

        .underline {
          margin-top: 20px;
          width: 100%;
          height: 0px;
          border: 2.5px solid $blue-color;
          background-color: $blue-color;
          opacity: 0;
          transition: left .4s ease;
        } 
        .active {
          opacity: 1;
          position: absolute;
          left: 0;
        }
      }
    }
    
    .separetor {
      height: 51px;
      border: 0.5px solid rgba($color: $white-color, $alpha: 0.5);
    }

    @media screen and (max-width: $small-screen) {
      min-width: 900px;
      width: 70vw;
      max-width: 90%;

      .controlHolder {
        width: 305px;

        .control {
          .h2 {
            font-size: 26px;
          }
    
          .underline {
            margin-top: 12px;
            border-width: 1.5px;
          }
        }
      }
      
      .separetor {
        height: 36px;
      }
    }
    @media screen and (max-width: 900px) {
      min-width: unset;
      width: 100%;
      max-width: 800px;
      
      padding-left: 10%;
      padding-right: 10%;

      flex-wrap: wrap;
      row-gap: 20px;

      .controlHolder {
        width: unset;

        .control {
          .underline {
            position: static;
            transition: opacity .25s ease-in-out;
          }
          .active {
            position: static;
          }  
        }
      }

      .lastSeparator {
        display: none;
      }
    }
    @media screen and (max-width: $mobile-screen) {
      padding-left: 5%;
      padding-right: 5%;

      row-gap: 10px;

      .controlHolder {
        .control {
          .h2 {
            font-size: 5.6vw;
          }
        }
      }

      .separetor {
        height: 7.3vw;
      }
    }
  }

  .content {
    margin-top: 76px;
    width: 100vw;

    display: flex;
    align-items: center;

    * {
      flex-shrink: 0;
      flex-grow: 0;
    }

    @media screen and (max-width: $small-screen) {
      margin-top: 60px;      
    }
    @media screen and (max-width: 900px) {
      margin-top: 0;
      flex-flow: column nowrap;
      align-items: center;
    }

    .mobileTitle {
      margin-top: 25px;
      margin-bottom: 50px;
      font-size: 36px;
      font-weight: 400;

      @media screen and (max-width: $mobile-screen) {
        margin-top: 21px;
        margin-bottom: 40px;
        font-size: 28px;
      }
    }

    .image {
      width: 47.1vw;
      max-width: 903px;
      height: 552px;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      animation: appear-animation .5s ease-out;

      @media screen and (max-width: $small-screen) {
        width: 45vw;
        height: 425px;
      }
      @media screen and (max-width: 900px) {
        animation: none;

        width: 100%;
        max-width: unset;
        height: 400px;
      }
      @media screen and (max-width: $mobile-screen) {
        height: 200px;
      }
    }

    .contentText {
      flex: 1;

      display: flex;
      justify-content: center;
      align-items: center;

      margin-right: 11px;

      @media screen and (max-width: $small-screen) {
        margin-right: 0.75vw;
      }
      @media screen and (max-width: 900px) {
        margin-top: 50px;

        width: 100%;
        min-height: 325px;

        display: block;
        margin-right: 0;
      }
      @media screen and (max-width: $mobile-screen) {
        margin-top: 40px;
        min-height: 320px;
      }

      .slide {
        width: 694px;
        max-width: 90%;

        animation: appear-animation .5s ease-out;

        .h2 {
          font-size: 30px;
          line-height: 50px;
          font-weight: 500;
        }

        .coolParagraph {
          margin-top: 50px;
          font-size: 18px;
          line-height: 26px;

          * {
            color: $white-color !important;
          }
          span, a {
            display: inline !important;
          }
        }

        button {
          margin-top: 50px;
        }

        @media screen and (max-width: $small-screen) {
          width: 500px;

          .h2 {
            font-size: 26px;
            line-height: 42px;
          }

          .coolParagraph {
            margin-top: 40px;
            font-size: 16px;
            line-height: 24px;
          }

          button {
            margin-top: 40px;
          }
        }

        @media screen and (max-width: 900px) {
          width: 100%;
          max-width: unset;
          padding-left: 10%;
          padding-right: 10%;

          button {
            float: right;
          }
        }

        @media screen and (max-width: $mobile-screen) {
          padding-left: 5%;
          padding-right: 5%;

          display: flex;
          flex-flow: column nowrap;
          align-items: center;

          .h2, .coolParagraph {
            width: 100%;
          }

          .h2 {
            font-size: 22px;
            line-height: 34px;
          }

          .coolParagraph {
            margin-top: 30px;
            font-size: 14px;
            line-height: 22px;
          }
          
          button {
            margin-top: 30px;
            float: unset;
          }
        }

      } 
    }

  }
}

.contentType {
  text-align: center;
  color: $blue-color;
  height: 0;
  transform: translateY(-40px);

  @media screen and (max-width: $small-screen) {
    transform: translateY(-28px) !important;
  }

  @media screen and (max-width: 900px) {
    transform: translateY(0) !important;
  }
}

.controls {
  height: 106px;

  @media screen and (max-width: $mobile-screen) {
    height: 86px;
  } 
}

// styling for Boi
.holderBoi {
  height: 60vh;

  .errorMessageBoi {
    color: $nevo-boi-dark-grey-color;
  }
}

.presentationBoi {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .contentControlsBoi {
    display: flex;
    justify-content: space-between;

    min-width: 1350px;
    width: 82vw;
    max-width: 1580px;

    .controlHolderBoi {
      cursor: pointer;
      user-select: none;

      width: 434px;

      display: flex;
      justify-content: center;

      .controlBoi {
        .h2Boi {
          text-align: center;
          color: $black-color;
          font-size: 40px;
          font-weight: 400;
        }

        .underlineBoi {
          margin-top: 20px;
          width: 100%;
          height: 0px;
          border: 2.5px solid $nevo-boi-dark-blue-color;
          background-color: $nevo-boi-dark-blue-color;
          opacity: 0;
          transition: left .4s ease;
        } 
        .activeBoi {
          opacity: 1;
          position: absolute;
          left: 0;
        }
      }
    }
    
    .separetorBoi {
      height: 51px;
      border: 0.5px solid rgba($color: $nevo-boi-lighter-grey-color, $alpha: 0.5);
    }

    @media screen and (max-width: $small-screen) {
      min-width: 900px;
      width: 70vw;
      max-width: 90%;

      .controlHolderBoi {
        width: 305px;

        .controlBoi {
          h2 {
            font-size: 26px;
          }
    
          .underlineBoi {
            margin-top: 12px;
            border-width: 1.5px;
          }
        }
      }
      
      .separetorBoi {
        height: 36px;
      }
    }
    @media screen and (max-width: 900px) {
      min-width: unset;
      width: 100%;
      max-width: 800px;
      
      padding-left: 10%;
      padding-right: 10%;

      flex-wrap: wrap;
      row-gap: 20px;

      .controlHolderBoi {
        width: unset;

        .controlBoi {
          .underlineBoi {
            position: static;
            transition: opacity .25s ease-in-out;
          }
          .activeBoi {
            position: static;
          }  
        }
      }

      .lastSeparatorBoi {
        display: none;
      }
    }
    @media screen and (max-width: $mobile-screen) {
      padding-left: 5%;
      padding-right: 5%;

      row-gap: 10px;

      .controlHolderBoi {
        .controlBoi {
          .h2Boi {
            font-size: 5.6vw;
          }
        }
      }

      .separetorBoi {
        height: 7.3vw;
      }
    }
  }

  .contentBoi {
    margin-top: 76px;
    width: 100vw;

    display: flex;
    align-items: center;

    * {
      flex-shrink: 0;
      flex-grow: 0;
    }

    @media screen and (max-width: $small-screen) {
      margin-top: 60px;      
    }
    @media screen and (max-width: 900px) {
      margin-top: 0;
      flex-flow: column nowrap;
      align-items: center;
    }

    .mobileTitleBoi {
      margin-top: 25px;
      margin-bottom: 50px;
      font-size: 36px;
      font-weight: 400;

      @media screen and (max-width: $mobile-screen) {
        margin-top: 21px;
        margin-bottom: 40px;
        font-size: 28px;
      }
    }

    .imageBoi {
      width: 47.1vw;
      max-width: 903px;
      height: 552px;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      animation: appear-animation .5s ease-out;

      @media screen and (max-width: $small-screen) {
        width: 45vw;
        height: 425px;
      }
      @media screen and (max-width: 900px) {
        animation: none;

        width: 100%;
        max-width: unset;
        height: 400px;
      }
      @media screen and (max-width: $mobile-screen) {
        height: 200px;
      }
    }

    .contentTextBoi {
      flex: 1;

      display: flex;
      justify-content: center;
      align-items: center;

      margin-right: 11px;

      @media screen and (max-width: $small-screen) {
        margin-right: 0.75vw;
      }
      @media screen and (max-width: 900px) {
        margin-top: 50px;

        width: 100%;
        min-height: 325px;

        display: block;
        margin-right: 0;
      }
      @media screen and (max-width: $mobile-screen) {
        margin-top: 40px;
        min-height: 320px;
      }

      .slideBoi {
        width: 694px;
        max-width: 90%;

        animation: appear-animation .5s ease-out;

        .h2Boi {
          font-size: 30px;
          line-height: 50px;
          font-weight: 500;
        }

        .coolParagraphBoi {
          margin-top: 50px;
          font-size: 18px;
          line-height: 26px;
          color: $nevo-boi-light-grey-color;

          * {
            color: $white-color !important;
          }
          span, a {
            display: inline !important;
          }
        }

        button {
          margin-top: 50px;
        }

        @media screen and (max-width: $small-screen) {
          width: 500px;

          .h2Boi {
            font-size: 26px;
            line-height: 42px;
          }

          .coolParagraphBoi {
            margin-top: 40px;
            font-size: 16px;
            line-height: 24px;
          }

          button {
            margin-top: 40px;
          }
        }

        @media screen and (max-width: 900px) {
          width: 100%;
          max-width: unset;
          padding-left: 10%;
          padding-right: 10%;

          button {
            float: right;
          }
        }

        @media screen and (max-width: $mobile-screen) {
          padding-left: 5%;
          padding-right: 5%;

          display: flex;
          flex-flow: column nowrap;
          align-items: center;

          .h2Boi, .coolParagraphBoi {
            width: 100%;
          }

          .h2Boi {
            font-size: 22px;
            line-height: 34px;
          }

          .coolParagraphBoi {
            margin-top: 30px;
            font-size: 14px;
            line-height: 22px;
          }
          
          button {
            margin-top: 30px;
            float: unset;
          }
        }
      } 
    }

  }
}

.contentTypeBoi {
  text-align: center;
  color: $nevo-boi-light-grey-color;
  height: 0;
  transform: translateY(-40px);

  @media screen and (max-width: $small-screen) {
    transform: translateY(-28px) !important;
  }

  @media screen and (max-width: 900px) {
    transform: translateY(0) !important;
  }
}

.controlsBoi {
  height: 106px;

  @media screen and (max-width: $mobile-screen) {
    height: 86px;
  } 
}